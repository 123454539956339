<template>
  <div class="warehouse-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      ref="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.freezerName"
            placeholder="冷柜名字"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select v-model="searchForm.status" clearable placeholder="状态">
            <el-option key="0" label="待开始" value="0" />
            <el-option key="1" label="配送中" value="1" />
            <el-option key="2" label="已完成" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getTaskDataList()"> 查询 </el-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <h5>配送员列表</h5>
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'dataList', getDetailList($event))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="配送员"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <h5>公司员工用餐对账信息</h5>
        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="mobile"
            header-align="center"
            align="center"
            label="手机号码"
          />
          <el-table-column
            prop="freezerName"
            header-align="center"
            align="center"
            label="冷柜名字"
          />
          <el-table-column
            prop="upperShelves"
            header-align="center"
            align="center"
            label="上货柜"
          />
          <el-table-column
            prop="middleShelves"
            header-align="center"
            align="center"
            label="中货柜"
          />
          <el-table-column
            prop="lowerShelves"
            header-align="center"
            align="center"
            label="下货柜"
          />
          <el-table-column
            prop="status"
            header-align="center"
            align="center"
            label="状态"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 0" size="small" type="danger">
                待开始
              </el-tag>
              <el-tag
                v-else-if="scope.row.status === 1"
                size="small"
                type="success"
              >
                配送中
              </el-tag>
              <el-tag
                v-else-if="scope.row.status === 2"
                size="small"
                type="success"
              >
                已完成
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            header-align="center"
            align="center"
            label="创建时间"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'detailList')"
          @current-change="$currentChange($event, 'detailList')"
          :current-page="detailListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="detailListSize"
          :total="detailListCount"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { threeListMixin, normal } from '@/mixins';
export default {
  mixins: [normal, threeListMixin],
  data() {
    return {
      searchForm: {
        name: '',
        mobile: '',
        idCard: '',
      },
      deliveryId: '',
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$api({
        url: '/delivery/deliveryuser/combo',
        after: (data) => {
          if (data && data.code === 0) {
            if (data.list?.length) {
              this.dataList = data.list;
              this.deliveryId = this.dataList[0].id;
            } else {
              this.dataList = [];
            }
          } else {
            this.dataList = [];
          }
        },
      });
    },
    // 获取数据列表
    getDetailList(row) {
      if (row) {
        this.deliveryId = row.id;
      }
      this.$api({
        url: '/delivery/deliverytask/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          deliveryId: this.deliveryId,
          freezerName: this.searchForm.freezerName,
          status: this.searchForm.status,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data.page && data.page.records?.length) {
              this.detailList = data.page.records;
              this.detailListCount = data.page.total;
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
  },
};
</script>

<style>
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
.is-horizontal {
  display: none;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
